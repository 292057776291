import DocsLayout from "../../../../src/layouts/DocsLayout";
import { Grid, Column } from 'src/components/molecules/Grid';
import { Section } from 'src/components/molecules/Section';
import { DocsPanel } from 'src/components/molecules/DocsPanel';
import { NewsletterForm } from 'src/components/molecules/NewsletterForm';
import { Link } from 'src/containers/Link';
import { Logo } from 'src/containers/Logo.new';
import { DocsBlogPosts } from 'src/containers/DocsBlogPosts';
import { Image } from 'src/containers/Image2';
import { GuideList, GuideListItem } from 'src/components/atoms/GuideList';
import { List, ListItem } from 'src/components/atoms/List';
import { Icon } from 'src/components/atoms/Icon';
import { TeaserList, TeaserListItem } from 'src/components/molecules/TeaserList';
import { MainTitle, MainSubTitle } from 'src/components/molecules/DocsTitle';
import { graphql } from 'gatsby';
import * as React from 'react';
export default {
  DocsLayout,
  Grid,
  Column,
  Section,
  DocsPanel,
  NewsletterForm,
  Link,
  Logo,
  DocsBlogPosts,
  Image,
  GuideList,
  GuideListItem,
  List,
  ListItem,
  Icon,
  TeaserList,
  TeaserListItem,
  MainTitle,
  MainSubTitle,
  graphql,
  React
};